import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { OnboardingApiService } from './onboarding-api.service';
import { OutputService } from './output.service';
import {Location} from '@angular/common';
import { Config, Page, PageGroup, ServicePlan } from '../models/config';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Vehicle, Contact, TrailerType, VehicleType, Registration } from '../models/output';
import { PrePopData } from '../models/prepopData';
import { PartialSignupData } from '../models/PartialSignupData';
import { DataService } from './data.service';
import {getSelectedIndex} from '../../components/stepper/stepperUtils';
import {PageGroups} from '../../components/stepper/PageGroups'
import { A2BAssociationID, _associationDataAPIResponse } from 'src/app/data/models/config';
import packageInfo from '../../../../package.json';
import { Output } from 'src/app/data/models/output';
import { OOSignupData } from '../models/OOSignupData';
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _completion = new BehaviorSubject({
    progress: '0%',
  });
  completion$ = this._completion.asObservable();

  private _hiddenElements = new BehaviorSubject({
    cost: false,
    navigation: false,
    footer: false,
  });
  hiddenElements$ = this._hiddenElements.asObservable();

  private _history: Page[] = [];
  public currentFlowKey: string = this.outputService.initialVehicleId;
  skippedVehicles: boolean = false;
  browserBackButtonUserOverride: boolean = false;
  config: Config;
  pageGroup: PageGroup;
  lastSavedPage: string;
  isOemPresentInURL: boolean = false;
  stepperSelectedIndex: number = 0;
  isPartialSaveinProcess: boolean = false;
  externalScripsLoaded: boolean = false;
  areAlltermsAndConditionsAccepted: boolean = false;
  completedPages:string[] = [""];
  ORDPCompletedPages:string[] = [""];
  visitedSteps:string[] = ["Coverage"];
  setepIdTemplate:string = "cdk-step-label-0-0";
  navigationInprogress:boolean = false;
  buildVersion:string = "";
  isAppVersionCompatible = false;
  isCurrentVehicleComplete:boolean = true;
  isCoverageStepCompleted:boolean = true;
  isExtrasStepCompletd:boolean = true;
  isFinishVehiclePopupActive:boolean =false;
  incompleteVehiclekey:string="";
  isCanadianCoverage: boolean = false;
  associationName:string = "";
  associationLogoImg:string = null;
  associationLogoName:string = "";
  promoBannerByLDWelcome: string;
  promoBannerByLDPayment: string;
  isAttribution:boolean = false;
  customerKey: string | null = null;
  prospectContactDetailsKey: string | null = null;
  partialDataSuccessPopup: boolean = false;
  partialDataErrorPopup: boolean = false;
  isLicensePlateValid: boolean = true;
  isFirstVehicleAdded: boolean = false;
  LicensePlateValidationisInprocess:boolean = false;
  currentStepName:string = "coverage/service-plan";
  coverageFeatureFlag: string;
  welcomeScreenFlag: boolean = true;
  private isPrepopFlow = false;
  private isAssociationPresent = false;
  private isShowPrepopModal = false;
  isVehicleAdditionFlowComplete = false;
  isTrailerAdditionFlowCompleted = false;
  private salesforceId: string;
  private prepopData: PrePopData;
  private partialsignupData : PartialSignupData;
  private isYearUpdatedByUserEvent = false;
  private isDeviceidPrefill = false;
  currentVehicleIndex:number;
  errorResponseData: string;
  private prepopID: string;
  lastSelectedCoveragePage: string;
  lastSelectedFlowPage: string;
  private ooSignupData: OOSignupData;
  numberofVehiclesFromServicePlan:number;
  orderTotalFromServicePlan: number;
  prePaidTollDepositFromServicePlan: number;
  displayPartialSaveComponent: boolean;
  flags: any;
  isBifurcationFlow: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private outputService: OutputService,
    private onboardingApiService: OnboardingApiService,
    private title: Title,
    private location: Location,
    private data: DataService
  ) { }

  get history(): Page[] {
    return [...this._history];
  }

  get currentPage(): Page {
    return this._history[this._history.length - 1];
  }

  pushHistory(screen:Page){
    this._history.push(screen);
  }

  sliceHistory(start:number,end:number): Page[] {
    return this._history.slice(start,end);
  }

  updateHistory(history:Page[]): void {
    this._history = history
  }

    updateVisitedSteps(step): void {
  if(!this.visitedSteps.includes(step)){
   this.visitedSteps.push(step);
  }
  }

  updateLastClickableStep(): void {
    const visitedStepsLength = this.visitedSteps.length;
      // Progress stepper is enabled only for ITM and Owner-op vendor.
      try {
        if(this.isITMVendor() || this.isOwnerOpVendor()){
          const stepElement: HTMLElement = document.querySelector(".mat-step-header");
            if (stepElement) {
              let stepId = stepElement.id || "cdk-step-label-1-0";
              this.setepIdTemplate = stepId;
              const activeStepId = stepId.replace(/.$/, (visitedStepsLength - 1).toString());
              const vehicleStep: HTMLElement = document.getElementById(`${activeStepId}`);
              vehicleStep.style.pointerEvents = "auto";
            }
         }
      }
      catch (error) {
       console.warn(error);
      }
  }


  getCurrentvehicleIndex(): number{
    const allVehicles = this.outputService.currentOutputState.vehicles;
    let currentVehicleIndex = 0;
    allVehicles.forEach((elem,index)=>{
     if(elem.key === this.currentFlowKey){
       currentVehicleIndex =  index;
    }
   });
  return currentVehicleIndex;
  }

  getNextVehiclekey(key:string): string{
    if(key === this.outputService.lastVehicleId || this.outputService.lastVehicleId === ""){
      return this.currentFlowKey;
    }
    else{
      const allVehicles = this.outputService.currentOutputState.vehicles;
      let currentVehicleIndex;
      allVehicles.forEach((elem,index)=>{
       if(elem.key === key){
         currentVehicleIndex =  index;
      }
     });
     if(allVehicles[currentVehicleIndex+1]){
      return allVehicles[currentVehicleIndex+1].key;
     }
     else{
      return this.currentFlowKey;
     }
    }
  }

  async getAssociationLogo(shorterName: string): Promise<void> {
    try {
      const resp: _associationDataAPIResponse = await this.onboardingApiService.getAssociationData(shorterName);
      if (resp.isSpecialUrlAllowed && resp.associationId) {
        this.setIsAssociationPresent(true);
        this.outputService.updateState({ attributionid: resp.associationId });
        this.updateUrlParams();
        this.validateAtoBAttributionID(resp.associationId.toString());
        if(resp.imageContent){
          this.associationLogoImg = "data:image/png;base64," + resp.imageContent;
        }
        this.associationLogoName = resp.associationName;
      }
      else{
        this.setIsAssociationPresent(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  validateAtoBAttributionID(associationID:string) {
    if (associationID === A2BAssociationID.AtoB || associationID === A2BAssociationID.uberFreightCarrierCard) {
      this.isAttribution = true;
    } else {
      this.isAttribution = false;
    }
  }

  isLastVehicle(): boolean{
    const vehiclesLength = this.outputService.currentOutputState.vehicles.length;
    const currentVehicleIndex = this.getCurrentvehicleIndex();
    return vehiclesLength-1 === currentVehicleIndex;
  }


  updateCurrentFlowKey(): void {
    if (this.history.length < 7) {
      let firstVehicle = this.outputService.currentOutputState.vehicles[0];
      if (firstVehicle?.key) {
        this.currentFlowKey = firstVehicle?.key;
      }
    }
  }

    next(screenName: string = null,isStepperCall?:boolean,skipHistoryUpdate?:boolean): void { 
    let partialData = this.outputService.mapModelToApiFormat();
    this.lastSavedPage = this.currentPage.name;
    this.updatePartialSignupStepperIndex(isStepperCall,screenName);
    if(!isStepperCall){
     this.completedPages.push(this.currentPage.name);}

    if (partialData.contacts != undefined && partialData.contacts[0].email != undefined) {
      this.onboardingApiService.postStorageHandler(partialData);
    }

    //skip vehicle-add if we're in the spreadsheet flow

    //TODO: might not need this now that workflow has changed, maybe spreadsheet
    //page can point directly to attribution page
    if ((screenName == "vehicle/add-more" || this.currentPage.next == "vehicle/add-more")
      && this.outputService.isSpreadsheetFlow) {
      screenName = "extras/attribution";
    }

    // Skip flow in post purchase
    if (this.currentPage.name == "vehicle/add-more" && this.lastSelectedFlowPage === 'noVehicleFlow' &&  this.isVehicleAdditionFlowComplete && screenName != "vehicle/summary") {
      this.lastSelectedFlowPage = 'normalFlow'
      screenName = "success";
    }

    this.updateHiddenElements({
      cost: false,
      navigation: false,
      footer: false,
    });

    const nextScreen = this.findScreen(
      screenName || (this.currentPage.next as string)
    );
    /*
      Mark the vehicle addition flow as completed in case of option available
      to add multiple vehicles and the last page in the flow is reached.
      This is to track whether the flow is completed or not in case of back button navigation
    */
    if (nextScreen?.name === "vehicle/add-more") {
      this.isVehicleAdditionFlowComplete = true;
    }
    if(nextScreen?.name === "vehicle/tractor-trailer") {
      this.isTrailerAdditionFlowCompleted = true;
    }
    // Never update key if it's already present.
    if (!skipHistoryUpdate) {
      if (Object.keys(nextScreen).includes("key")) {
        this._history.push(nextScreen);
      }
      else {
        this._history.push({ ...nextScreen, key: this.currentFlowKey });
      }
    }
    this.updateCurrentFlowKey();
    // Update progress bar percentage for post purchase vehicle flow.
    if(this.lastSelectedFlowPage === "noVehicleFlow" && this.currentPage?.progressFlowB){
      this._completion.next({ progress: this.currentPage.progressFlowB });
    }
    else {
      this._completion.next({ progress: this.currentPage.progress });
    }

    // Add loading spinner until vehicles lazy loaded module is fetched
    const nextModules = ["vehicle/type", "contact/info", "extras/attribution", "coverage/car-make", "trailer/type", "payment/summary", "ORDP", "extras/shippingaddress","extras/shippingaddressspeed"];
    if(nextModules.includes(nextScreen?.name)){
      this.navigationInprogress = true;
    } 
    
    this.router.navigate([nextScreen.name], {
      relativeTo: this.route.firstChild,
      replaceUrl: true,
    });
    this.setMetaTitleForScreen(null,nextScreen.metaTitle);
  }

  async previous() {
    if (this._history.length == 1) {
      return;
    }

      this.updateHiddenElements({
        cost: false,
        navigation: false,
        footer: false,
      });

      this._history.pop();
      this.currentFlowKey = this.currentPage.key;
  // Update progress bar percentage for post purchase vehicle flow.
    if(this.lastSelectedFlowPage === "noVehicleFlow" && this.currentPage?.progressFlowB){
        this._completion.next({ progress: this.currentPage.progressFlowB });
         }
    else {
          this._completion.next({ progress: this.currentPage.progress });
        }    
    // Update Stepper active state while navigating back.
    if(this.config.vendorId === "owner-op" || this.isITMVendor()){
      this.outputService.nextPage(this.currentPage.name);
    }  
    this.currentStepName = this.currentPage.name;
    try {
      await this.router.navigate([this.currentPage.name], {
        relativeTo: this.route.firstChild,
        replaceUrl: true,
      });
    } catch(err){
      console.warn(err);
    }
      this.setMetaTitleForScreen(null,this.currentPage.metaTitle);
  }
 
  updateHiddenElements(hiddenElements: {
    cost?: boolean;
    navigation?: boolean;
    footer?: boolean;
  }): void {
    setTimeout(() => {
      this._hiddenElements.next({
        ...this._hiddenElements.value,
        ...hiddenElements,
      });
    }, 0);
  }

  private findScreen(name: string): Page {
    const workflow: Page[] = this.config.screens;
    let newScreen: Page;
    // search first by alias, because names can be duplicated
    newScreen = workflow.find((screen) => screen.alias == name);
    // if no alias matches, search by name
    if (!newScreen) {
        newScreen = workflow.find((screen) => screen.name == name);
    }
    if (!newScreen) {
      this.router.navigate(["support/customer-support"], { replaceUrl: true });
      throw Error(
        `No screen alias or component name in the json config matches '${name}'`
      );
    }
    return newScreen;
  }

  getCarMakeId(carMake: string): number {
    let servicePlan = this.findScreen('coverage/service-plan');
    if (carMake) {
      const makeIdForCarMake = servicePlan?.oems.find(oemObj =>
        oemObj.name === carMake
      );
      return makeIdForCarMake?.oemId;
    }
    return null;
  }

  // all trailer types are mapped to 'TRAILER' for the form in this component
  isTrailerType(type: string): boolean {
    if (!type) return false

    for (let key in TrailerType) {
      if (type == TrailerType[key]) {
        return true
      }
    }
    return false
  }

  isVehicleType(type: string): boolean {
    if (!type) return false

    for (let key in VehicleType) {
      if (type == VehicleType[key]) {
        return true
      }
    }
    return false
  }

  // Below method helps to find wheteher given vehicle details are completed by looking for last property of vehicle
  areAllVehicleComplete(): boolean {
    const allVehicles = this.outputService.currentOutputState.vehicles;
    let areAllVehiclesComplete = true;
    allVehicles.forEach((vehicle)=>{
      areAllVehiclesComplete = this.isCurrentvehicleComplete(vehicle);
  })
    return areAllVehiclesComplete;
  }

  isCurrentvehicleComplete(vehicle){
    const trailers = ["TTT", "FWT", "STT"];
    const VehiclesExceptTractor = ["BOX", "VAN", "PUT", "MBUS", "BUS", "RV"];
    if (vehicle) {
      const subjectVehicleType = vehicle.type;
      if (VehiclesExceptTractor.includes(subjectVehicleType)) {
        const lastPropertyOfVehiclesExceptTractor = "weight";
        this.incompleteVehiclekey = vehicle.key;
        return Object.keys(vehicle).includes(lastPropertyOfVehiclesExceptTractor);
      }
      if (trailers.includes(subjectVehicleType)) {
        const lastPropertyOfTrailers = "otheraxles";
        this.incompleteVehiclekey = vehicle.key;
        return Object.keys(vehicle).includes(lastPropertyOfTrailers) && Object.keys(vehicle).includes("id");
      } else {
        const lastPropertyOfTractor = "length";
        const lastPropertyOfTractorOther = "trailers_greater_than_28_and_a_half_feet"
        this.incompleteVehiclekey = vehicle.key;
        if(Object.keys(vehicle).includes(lastPropertyOfTractor)){
          return true;
        }
        else if(Object.keys(vehicle).includes(lastPropertyOfTractorOther) && Object.keys(vehicle).includes("otheraxles")){
         return true;
        }
        else{
          return false;
        }
      }
    }
  }

  FilterVehicles(vehicles:Vehicle[]){
    let vehiclesArray = vehicles.filter(element => {
      return !this.isTrailerType(element.type);
    });
    return vehiclesArray;
  }

  FilterTrailers(vehicles:Vehicle[]){
    let TrailersArray = vehicles.filter(element => {
      return this.isTrailerType(element.type);
    });
    return TrailersArray;
  }

  CurrentVehicle(type:string){
  let SavedVehicles = this.outputService.currentOutputState.vehicles;
   if (type == "Vehicle"){
    let AllFilteredVehicles = this.FilterVehicles(SavedVehicles||[]);
    return AllFilteredVehicles[AllFilteredVehicles.length-1];
   }

   if (type == "Trailer"){
    let AllTrailers = this.FilterTrailers(SavedVehicles||[]);
    return AllTrailers[AllTrailers.length-1];
   }

  }

  ReturnCurrentVehicle() {
    let currentVehicle = this.CurrentVehicle("Vehicle");
    if (this.outputService.currentFlow == "Trailer") {
      currentVehicle = this.outputService.currentOutputState.vehicles[this.outputService.currentOutputState.vehicles.length-1];
    }
    return currentVehicle;
  }

  ReturnCurrentVehicleITM(isITMVendor:boolean) {
    let currentVehicle = this.outputService.lastVehicleAddedState ?
    this.outputService.lastVehicleAddedState :
    this.outputService.currentVehicleState;

    const currentVehicleState = this.CurrentVehicle("Vehicle");

    if (!isITMVendor){
      currentVehicle = currentVehicleState;
    }

    return currentVehicle;
  }

  // Get exact index of current added vehile in AllVehiles Array to Update vehicle Accordingly
  currentVehileIndex(SotredVehicle:Vehicle) {
  let AllVehicles = this.outputService.currentOutputState.vehicles;
  let Vehicleindex:number =AllVehicles.length-1;
  if(SotredVehicle?.id == undefined) {
    return Vehicleindex;
  }
  AllVehicles.forEach((element,index)=>{
   if(SotredVehicle.id == element.id && SotredVehicle.type == element.type){
    Vehicleindex = index;
   }
  })
  return Vehicleindex;
  }

  // Only meant to be used by ConfigResolver on application load
  async getConfigFromServer(id: string = 'default'): Promise<Config> {
    if (environment.useMockDB) {
      // hits local mock-db
      const response = await this.http
        .get<any>(`api/partners?vendorId=${id}`)
        .toPromise();
      this.config = response[0];
    } else {
      // passed along to production service
      try {
        this.config = await this.onboardingApiService.getVendorConfig(id);
      }
      catch (err) {
        console.error(err.message);
      }
    }

    if (this.config) {
      this._history.push(this.config.screens[0]);
    // Update progress bar percentage for post purchase vehicle flow.
    if(this.lastSelectedFlowPage === "noVehicleFlow" && this.currentPage?.progressFlowB){
      this._completion.next({ progress: this.currentPage.progressFlowB });
    }
    else {
      this._completion.next({ progress: this.currentPage.progress });
    }

      document.documentElement.style.setProperty(
        '--primary-color',
        this.config.styles.primaryColor
      );
      document.documentElement.style.setProperty(
        '--secondary-color',
        this.config.styles.secondaryColor
      );
      document.documentElement.style.setProperty(
        '--button-text-color',
        this.config.styles.buttonTextColor
      );
      document.documentElement.style.setProperty(
        '--active-input-color',
        this.config.styles.activeInputColor
      );
      document.documentElement.style.setProperty(
        '--button-disabled-color',
        this.config.styles.buttonDisabledColor
      );
      document.documentElement.style.setProperty(
        '--footer-background-color',
        this.config.styles.footerBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--tooltip-background-color',
        this.config.styles.tooltipBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--toggle-background-color',
        this.config.styles.toggleBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--mat-progress-bar-buffer',
        this.config.styles.matProgressBarBuffer
      );
      document.documentElement.style.setProperty(
        '--save-progress-button-color',
        this.config.styles.saveProgressButtonColor
      );
      document.documentElement.style.setProperty(
        '--selected-background-color',
        this.config.styles.selectedBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--stepper-progress-color',
        this.config.styles.stepperProgressColor
      );
    }

    return this.config;
  }

  isITMVendor(): boolean {
    return this.config.vendorId === "itm"
  }

  isOwnerOpVendor(): boolean {
    return this.config.vendorId === "owner-op" || this.config.vendorId === "mercer";
  }

  isEFSVendor(): boolean {
    return this.config.vendorId === "efs";
  }

  isMercerVendor(): boolean {
    return this.config.vendorId === "mercer";
  }

  isBennettVendor(): boolean {
    return this.config.vendorId === "bennett";
  }

  isSevicePlanPage(): boolean {
    return this.currentPage.name === "coverage/service-plan";
  }

  toSentenceCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  setMetaTitleForScreen(routeSnapshot: ActivatedRouteSnapshot, title = ''): void {

    /*
      Set the page title from the title string, if the title string is provided.
      Else, set the page title by fetching its value as per the route and config meta data.
    */
    if (title) {
      this.title.setTitle(title);
      return;
    }

    // Get meta title by navigated route from the config
     title = this.config.screens.find(screen => screen.name === this.currentPage?.name)?.metaTitle;

    /*
     If oem is present in the route query param (special case for Welcome screen),
     convert the oem value to sentence case and append to title
    */
    if (routeSnapshot?.queryParams.oem) {
      const oem = routeSnapshot?.queryParams.oem;
      title += ` ${this.toSentenceCase(oem)}`;
    }

    // If title value resolves to empty, set it to as 'Account Activation'
    this.title.setTitle(title || 'Account Activation');
  }

  removePreviousPagesFromNavHistory(): void {
    this._history = this._history.slice(-1);
  }

  getIsPrepopFlow(): boolean {
    return this.isPrepopFlow;
  }

  getIsAssociationPresent(): boolean {
    return this.isAssociationPresent;
  }

  setIsAssociationPresent(status:boolean) {
    this.isAssociationPresent = status;
  }

  setIsPrepopFlow(isPrepopFlow: boolean): void {
    this.isPrepopFlow = isPrepopFlow;
  }

  getIsDeviceidPrefill(): boolean {
    return this.isDeviceidPrefill;
  }

  setIsDeviceidPrefill(isDeviceidPrefill: boolean): void {
    this.isDeviceidPrefill = isDeviceidPrefill;
  }



  getIsShowPrepopModal(): boolean {
    return this.isShowPrepopModal;
  }

  setIsShowPrepopModal(showPrepopModal: boolean): void {
    this.isShowPrepopModal = showPrepopModal;
  }

  setSalesForceId(id: string) {
    this.salesforceId = id;
  }
  getSalesForceId() {
    return this.salesforceId;
  }

  setPrePopId(id:string){
    this.prepopID = id;
  }

  getPrepopId(){
    return this.prepopID
  }

  setPrepopData(data: PrePopData) {
    this.prepopData = data;
    this.bindPrepopDataToOutputState();
  }

  bindPrepopDataToOutputState() {
    const outputState = this.outputService.currentOutputState;
    let phoneNumber = this.prepopData?.phoneNumber;
    if (this.prepopData?.phoneNumber && this.prepopData?.phoneNumber.indexOf('+1') === 0) {
     phoneNumber = this.prepopData.phoneNumber.substring(2);
    }
    outputState.contacts = [
      {
        FirstName: this.prepopData?.firstName,
        LastName: this.prepopData?.lastName,
        email: this.prepopData?.emailAddress,
        phone: phoneNumber,
      },
    ];
    outputState.oem = this.prepopData?.vehicleMake?.toLowerCase();
    outputState.oemId = this.getCarMakeId(outputState.oem);
    this.outputService.updateState(outputState);

    const updatedVehicle: Vehicle = {
      year: this.prepopData?.vehicleYear,
      make: outputState.oem,
      model: this.prepopData?.vehicleModel,
      transponderNumber: this.prepopData?.deviceNumber
    };

    this.outputService.updateVehicleState(updatedVehicle,0);
  }

  setPartialSignupData(data: PartialSignupData) {
    if(data){
      this.partialsignupData = data;
      this.bindPartialsignupDataToOutputState();
    }
  }

  setOOSignUpData(data: OOSignupData) {
    this.ooSignupData = data;
    this.bindOOSignupDataToOutputState();
 }

  updatePartialSignupStepperIndex(isStepperCall:boolean,screenName:string|null){
    const defaultStepIndex = 0;
    if(this.isITMVendor() || this.isOwnerOpVendor() && isStepperCall && screenName){
      try{
       const isFlowB = this.lastSelectedFlowPage == 'noVehicleFlow'; 
       const {nextStepIndex} = getSelectedIndex(screenName, PageGroups, this.isOwnerOpVendor(), this.isITMVendor(), this.isCanadianCoverage, isFlowB );
       setTimeout(()=>{this.stepperSelectedIndex = nextStepIndex;});
       return nextStepIndex;
      }
      catch(error){
      console.warn(error);
      return defaultStepIndex;
      }
     }
     else {
      return defaultStepIndex;
     } 
  }

  bindPartialsignupDataToOutputState() {
    let jsonObj = JSON.parse(this.partialsignupData.partialSignupPayload); // string to "any" object first
    let outputState = jsonObj as OutputService
    let plan = jsonObj.servicePlan as ServicePlan
    let baseplan = jsonObj.baseServicePlan ? jsonObj.baseServicePlan as ServicePlan : null
    this.outputService.isPartialSignupFlow = true;
   
    this.updateServicePlan(plan, baseplan);
    this.outputService.updateState(outputState);

    if (this.outputService.currentOutputState.addOnIds) {
      if (this.outputService.currentOutputState.addOnIds.includes(17)) {
        this.outputService.ticketVal = "NO";
      } else {
        this.outputService.ticketVal = "YES";
      }
    } else {
      this.outputService.ticketNeeded = '';
      this.outputService.ticketVal = '';
    }

    if (this.outputService.currentOutputState.company?.name == "N/A") {
      this.outputService.currentOutputState.company.name = "";
    }
    
    if (this.isITMVendor()) {
      const isTempFlag: any = this.outputService.currentOutputState.vehicles[0]?.isTempFlag;
      if (isTempFlag) {
        this.outputService.setLicensePlateType("Temp");
      } else {
        this.outputService.setLicensePlateType("Permanent");
      }
    }
    if (this.isAppVersionCompatible) {
      this._history = null
      this._history = JSON.parse(this.partialsignupData.partialSignupNavigationHistory);
    }
    else {
      let firstDetailsPage = this.config.screens[1]?.name;
      if (this.isITMVendor()) {
        firstDetailsPage = this.config.screens[3]?.name;
      }
      this._history = null
      const AllHistory = JSON.parse(this.partialsignupData.partialSignupNavigationHistory);
      if (AllHistory) {
        this._history = [];
        this.isVehicleAdditionFlowComplete = false;
        const stepIndexAsperLastPage = this.updatePartialSignupStepperIndex(true, firstDetailsPage);
        this.data.updateStepperState(stepIndexAsperLastPage);
        this.stepperSelectedIndex = stepIndexAsperLastPage;
        this.data.updateStepperState(stepIndexAsperLastPage);
        this.makeCurrentStepClickable(stepIndexAsperLastPage);
        try {
          if (this.isITMVendor()) {
            for (let i = 0; i < 4; i++) {
              const page = this.config.screens[i]
              // Push pages until cantact-info page
              this._history.push(page);
              //Update completed pages related to stepper
              this.completedPages.push(page.name);
            }
            //Update currentFlowKey
            for (let page of AllHistory) {
              if (page.name === firstDetailsPage) {
                this.currentFlowKey = page?.key;
                return;
              }
            }
          }
          else {
            switch (true) {
              case this.config.vendorId === "owner-op":
                // First screen of application is welcome page.
                this._history.push(this.config.screens[0]);
                // Second screen of application is contact-info page.
                this._history.push(this.config.screens[1]);
                break;
              case this.isEFSVendor():
                for (let i = 0; i < 3; i++) {
                  this._history.push(this.config.screens[i]);
                }
                break;
              case this.isMercerVendor():
                for (let i = 0; i < 4; i++) {
                  this._history.push(this.config.screens[i]);
                }
                break;
              default:
                for (let i = 0; i < 2; i++) {
                  this._history.push(this.config.screens[i]);
                }
                break;
            }
          }
        }
        catch (error) {
          console.warn(error);
        }

      }
    }

    const NavigationDependency = JSON.parse(this.partialsignupData.partialSignupNavigationDepdendents);
    if (NavigationDependency) {
      this.buildVersion = NavigationDependency?.cofigDependencys?.buildVersion ? NavigationDependency?.cofigDependencys?.buildVersion : "";
      if (this.isAppVersionCompatible) {
        this.isVehicleAdditionFlowComplete = NavigationDependency.cofigDependencys.isVehicleAdditionFlowComplete;
        this.currentFlowKey = NavigationDependency.cofigDependencys.currentFlowKey;
        this.stepperSelectedIndex = NavigationDependency.cofigDependencys.stepperSelectedIndex;
        this.data.updateStepperState(NavigationDependency.cofigDependencys.stepperSelectedIndex);
        const stepIndexAsperLastPage = this.updatePartialSignupStepperIndex(true, this.partialsignupData.partialSignupLastPage);
        this.data.updateStepperState(stepIndexAsperLastPage);
        this.makeCurrentStepClickable(stepIndexAsperLastPage);
        //Update completed pages related to stepper
        this.completedPages = NavigationDependency?.cofigDependencys?.completedPages || [];
        this.visitedSteps = NavigationDependency?.cofigDependencys?.visitedSteps || [];
        this.isFirstVehicleAdded = NavigationDependency?.cofigDependencys?.isFirstVehicleAdded === undefined ? true : NavigationDependency?.cofigDependencys?.isFirstVehicleAdded;
        this.updateLastClickableStep();
      }

      this.outputService.initialVehicleId = NavigationDependency?.outputDependencys?.initialVehicleId;
      this.outputService.signUpVal = NavigationDependency?.outputDependencys?.signUpVal;
      this.outputService.ticketVal = NavigationDependency?.outputDependencys?.ticketVal;
      this.associationName = NavigationDependency?.cofigDependencys?.associationName || "";
      this.associationLogoName = NavigationDependency?.cofigDependencys?.associationLogoName || "Association logo";
      this.promoBannerByLDWelcome = NavigationDependency?.cofigDependencys?.promoBannerByLDWelcome;
      this.promoBannerByLDPayment = NavigationDependency?.cofigDependencys?.promoBannerByLDPayment;
      this.isAttribution = NavigationDependency?.cofigDependencys?.isAttribution;
      this.isAssociationPresent = NavigationDependency?.cofigDependencys?.isAssociationPresent;
      this.prepopID = NavigationDependency.cofigDependencys.prepopID;
      this.lastSelectedCoveragePage = NavigationDependency?.cofigDependencys?.lastSelectedCoveragePage;
      this.isDeviceidPrefill = NavigationDependency?.cofigDependencys?.isDeviceidPrefill || false;
      if (this.associationName) {
        this.getAssociationLogo(this.associationName);
      }
      this.lastSelectedFlowPage = NavigationDependency?.cofigDependencys?.lastSelectedFlowPage;
      this.numberofVehiclesFromServicePlan = NavigationDependency?.cofigDependencys?.numberofVehiclesFromServicePlan;
      this.orderTotalFromServicePlan = NavigationDependency?.cofigDependencys?.orderTotalFromServicePlan;
      this.prePaidTollDepositFromServicePlan = NavigationDependency?.cofigDependencys?.prePaidTollDepositFromServicePlan;
    }

    if (baseplan) {
      this.data.updateBasePlanState(baseplan);
    }

    if (plan) {
      this.data.updatePlanState(plan);
    }
  }

  bindOOSignupDataToOutputState()
  {
    //let jsonObjfname = JSON.parse(this.ooSignupData.firstName);
    //let jsonobjlname = JSON.parse(this.ooSignupData.lastName);

    const outputState = this.outputService.currentOutputState;
    //outputState.company.address = this.isVendorITM? 'N/A':this.addressPointer.form.value;
    //outputState.company.dotnumber = this.isVendorITM? '': this.form.controls.dotNumber.value;
    if (!outputState.contacts) {
      outputState.contacts = [
        {
          FirstName: undefined,
          LastName: undefined,
          email: undefined,
          phone: undefined,
          phoneSmsOptIn: undefined
        },
      ];
    }

    if (!outputState.company) {
      outputState.company = {};
    }

    outputState.contacts[0].FirstName = this.ooSignupData.firstName;
    outputState.contacts[0].LastName = this.ooSignupData.lastName;
    outputState.company.name = this.ooSignupData.companyName || (this.isITMVendor()? '': 'N/A');
    outputState.contacts[0].email = this.ooSignupData.emailAddress;
    outputState.contacts[0].phone = this.ooSignupData.phoneNumber;
    outputState.contacts[0].phoneSmsOptIn = this.ooSignupData.optInStatus? true:false;

    this.outputService.updateState(outputState);

    //let outputState = jsonObj as OutputService
  }

  updateServicePlan(plan: ServicePlan = null, baseplan: ServicePlan = null) {
    if(plan) {
      const update: Output = { servicePlan: plan };
      this.outputService.updateState(update);
    }
    if(baseplan) {
      const update: Output = { baseServicePlan: plan };
      this.outputService.updateState(update);
    }
  }

  makeCurrentStepClickable(stepIndex){
      // Currently stepper component is active for only ITM and Owner-op vendor.
    if ((this.isOwnerOpVendor() || this.isITMVendor()) && stepIndex) {
      const vehicleStep:HTMLElement = document.getElementById(`cdk-step-label-0-${stepIndex}`);
      try {
        if (vehicleStep) {
          vehicleStep.style.pointerEvents = "auto";
        }
      }
      catch(error){
       console.warn(error);
      }
     }
  }

  clearPrepopDataExceptOem() {
    const outputState = this.outputService.currentOutputState;
    outputState.contacts = [
      {
        FirstName: null,
        LastName: null,
        email: null,
        phone: null,
      },
    ];
    this.outputService.updateState(outputState);
    const updatedVehicle: Vehicle = {
      year: null,
      model: null,
      transponderNumber: null,
      vin:null,
      licenseplate:null
    };

    this.outputService.updateVehicleState(updatedVehicle,0);
  }

  setIsYearUpdatedByUserEvent(isYearUpdatedByUserEvent: boolean){
    this.isYearUpdatedByUserEvent = isYearUpdatedByUserEvent;
  }

  getIsYearUpdatedByUserEvent():boolean{
    return this.isYearUpdatedByUserEvent;
  }

  updateUrlParams(){
    if(this.isAssociationPresent){;
      this.location.replaceState(`${this.router.url}/${this.associationName}`);
    }
  }

  get currentClassState(): object {
   const state = {
    buildVersion: packageInfo.version,
    completion$: this._completion.value,
    hiddenElements$: this._hiddenElements.value,
    skippedVehicles: this.skippedVehicles,
    browserBackButtonUserOverride: this.browserBackButtonUserOverride,
    lastSavedPage: this.currentPage.name,
    stepperSelectedIndex: this.stepperSelectedIndex,
    completedPages: this.completedPages,
    visitedSteps: this.visitedSteps,
    isFirstVehicleAdded: this.isFirstVehicleAdded,
    setepIdTemplate: this.setepIdTemplate,
    associationName: this.associationName,
    associationLogoName: this.associationLogoName,
    promoBannerByLDWelcome: this.promoBannerByLDWelcome,
    promoBannerByLDPayment: this.promoBannerByLDPayment,
    isAttribution: this.isAttribution,
    currentStepName: this.currentStepName,
    currentFlowKey:this.currentFlowKey,
    isPrepopFlow: this.isPrepopFlow,
    isAssociationPresent: this.isAssociationPresent,
    isShowPrepopModal: this.isShowPrepopModal,
    isVehicleAdditionFlowComplete: this.isVehicleAdditionFlowComplete,
    salesforceId: this.salesforceId,
    prepopData: this.prepopData,
    isYearUpdatedByUserEvent: this.isYearUpdatedByUserEvent,
    isDeviceidPrefill: this.isDeviceidPrefill,
    prepopID: this.prepopID,
    lastSelectedCoveragePage: this.lastSelectedCoveragePage,
    orderTotalFromServicePlan: this.orderTotalFromServicePlan,
    prePaidTollDepositFromServicePlan:  this.prePaidTollDepositFromServicePlan,
    lastSelectedFlowPage: this.lastSelectedFlowPage,
    numberofVehiclesFromServicePlan: this.numberofVehiclesFromServicePlan
   }
   return state;
  }

  async savePartialData(){
    let NavigationData = {
      cofigDependencys: this.currentClassState,
      outputDependencys: this.outputService.currentClassState
    }
    let payload = {
    customerKey: this.customerKey,
    firstName: "",
    lastName: "",
    companyName: "",
    emailAddress: "",
    lastPage: this.currentPage.name,
    payload: JSON.stringify(this.outputService.currentOutputState),
    NavigationHistory:JSON.stringify(this._history),
    NavigationDepdendents:JSON.stringify(NavigationData),
    vendor: this.config.vendorId,
    phoneNumber: ""
    }
    const contactDetails: Contact = this.outputService.currentOutputState.contacts[0];
    //Check for contact info
    if(contactDetails){
      payload.firstName = contactDetails.FirstName;
      payload.lastName = contactDetails.LastName;
      payload.emailAddress = contactDetails.email;
      payload.phoneNumber = contactDetails.phone;
    }
    else{
      window.alert("Contact details not present");
      return;
    }
    const companyDetails = this.outputService.currentOutputState.company;
    if(companyDetails){
      payload.companyName = companyDetails.name;
    }
    try{
      //make partialSave loader in progress
      this.isPartialSaveinProcess = true;
      const response = await this.onboardingApiService.postPartialCustomerData(payload);
      if(response.success && response.id ){
        this.customerKey = response.id;
        this.isPartialSaveinProcess = false;
        this.partialDataSuccessPopup = true;
       }
       else{
        this.isPartialSaveinProcess = false;
        this.partialDataErrorPopup = true;
       }
      return response;
    }
    catch(error){
      this.isPartialSaveinProcess = false;
      this.partialDataErrorPopup = true;
     return ;
    }
  }

  async saveContactDetails(contacts){
    try{
      const response = await this.onboardingApiService.saveContactDetails(contacts);
      if(response.success && response.id ){
        this.prospectContactDetailsKey = response.id;
       }
      return response;
    }
    catch(error){
      console.warn(error);
    }
  }


  async getVehicleLicenseData() {
    this.LicensePlateValidationisInprocess = true;
    let payload = {
      LicensePlateNumber: "",
      State: "",
      PlateTypeID: null
    }

    const currentVehiclesIndex = this.getCurrentvehicleIndex();
    const allVehicles = this.outputService.currentOutputState.vehicles;

    const vehicleRegistrationPlateDetails : Registration = allVehicles[currentVehiclesIndex].licenseplate;
    if(vehicleRegistrationPlateDetails){
      payload.LicensePlateNumber = vehicleRegistrationPlateDetails.number;
      payload.State = vehicleRegistrationPlateDetails.state;
    }
    
    if((allVehicles[currentVehiclesIndex].type == TrailerType.STANDARD) || (allVehicles[currentVehiclesIndex].type == TrailerType.FIFTHWHEEL)  || (allVehicles[currentVehiclesIndex].type == TrailerType.TRACTORTRAILER)) {
      payload.PlateTypeID = 270;
    } else {
      payload.PlateTypeID = 269;
    }


    try {
     const response = await this.onboardingApiService.getLicensePlateData(payload);
     if(response){
      this.isLicensePlateValid = response.isEligible;
      if(response.errors) {
        this.errorResponseData = response.errors[0].message;
      }
     }
     this.LicensePlateValidationisInprocess = false;
    }catch (error) {
       this.LicensePlateValidationisInprocess = false;
       console.error(error);
    }
  }

  getFlagData(flagName: string): any {
    let flag: any;
    flag = this.flags[flagName];
    return flag;
  }

}
